// Angular
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/animations';
import '@angular/core';
import '@angular/common';
import '@angular/forms';
import '@angular/router';

// Dependencies
import 'rxjs';
import 'bootstrap-loader';
import 'jquery';
import 'jquery-ui-bundle';
